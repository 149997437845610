import * as React from 'react'
import { graphql } from 'gatsby'
import NavigablePage from 'src/components/layout/NavigablePage'

const IndexPage = ({ data }) => {
  const translation = data.directus.navigable_pages[0].translations[0]
  if (!translation) {
    return <h1 className="text-red">Missing translation</h1>
  }

  const { title, metas, titlePart1, titlePart2, sections } = translation

  return (
    <NavigablePage
      title={title}
      metas={metas}
      titlePart1={titlePart1}
      titlePart2={titlePart2}
      sections={sections}
    />
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    directus {
      navigable_pages(filter: { slug: { _eq: "/privacy-policy" } }) {
        translations(filter: { languages_code: { code: { _eq: $language } } }) {
          title
          titlePart1: title_part_1
          titlePart2: title_part_2
          metas {
            id
            name
            content
          }
          sections(sort: "order") {
            id
            slug
            title
            items(sort: "order") {
              item {
                ... on DirectusData_text_items {
                  id
                  type
                  content
                }
                ... on DirectusData_foldable_list_items {
                  id
                  type
                  showMoreLabel: show_more_label
                  elements(sort: "order") {
                    id
                    title
                    content
                  }
                }
                ... on DirectusData_download_list_items {
                  id
                  type
                  elements(sort: "order") {
                    id
                    file {
                      id
                    }
                    description
                  }
                }
                ... on DirectusData_follow_bullet_list_items {
                  id
                  type
                  elements(sort: "order") {
                    id
                    description
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
